$('a[href^="#"').on('click', function () {
	let href = $(this).attr('href');
	$('html, body').animate({
		scrollTop: $(href).offset().top
	});
	return false;
});

function showHidePassword(target) {
	let input = document.getElementById('password-input');
	if (input.getAttribute('type') == 'password') {
		target.classList.add('view');
		input.setAttribute('type', 'text');
	} else {
		target.classList.remove('view');
		input.setAttribute('type', 'password');
	}
	return false;
}

window.showHidePassword = showHidePassword;